var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{staticClass:"greenHeader secondary elevation-1",attrs:{"headers":_vm.headers,"items":_vm.testScenarioLineStep,"items-per-page":20,"sort-by":['Number'],"sort-desc":[false],"item-key":"id","disable-pagination":"","dense":"","hide-default-footer":true,"loading":_vm.isFindTestScenarioLineStepPending,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:`item.Number`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.Number)+" ("+_vm._s(item.process_step?.Number)+") ")]}},{key:`item.color`,fn:function({ item }){return _vm._l((_vm.group.find((f) => f.id == item.GroupId)?.allGroups),function(color){return _c('span',{key:color.id},[(color.Loop)?_c('span',[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"circle pointer",style:({
                  background: color.Color,
                  color: _vm.applyDark(color.Color) ? 'white' : 'black',
                }),on:{"click":function($event){return _vm.addLoop(item)}}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.Groups.find((f) => f.GroupId == color.id) ?.GroupIteration + 1))])]}}],null,true)},[_vm._v(_vm._s(color.Name))])],1):_c('span',[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"circle",style:({
                  background: color.Color,
                  color: _vm.applyDark(color.Color) ? 'white' : 'black',
                })},'span',attrs,false),on),[_vm._v("  ")])]}}],null,true)},[_vm._v(_vm._s(color.Name))])],1)])})}},{key:`item.Fields`,fn:function({ item }){return [_c('TestScenarioLineStepField',{attrs:{"keyReload":_vm.keyReload,"testScenarioLineStep":item}})]}},{key:`item.process_step.status.Testable`,fn:function({ item }){return [(item.process_step?.status.Testable)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }