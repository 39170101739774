var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-switch',{staticClass:"mb-5 ml-4",attrs:{"label":"Show All","hide-details":""},model:{value:(_vm.showAll),callback:function ($$v) {_vm.showAll=$$v},expression:"showAll"}}),(_vm.filteredStepsOnSelection)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredStepsOnSelection,"items-per-page":20,"item-key":"Number","disable-pagination":"","show-select":"","show-expand":"","single-expand":"","dense":"","hide-default-footer":true,"loading":_vm.isPending,"loading-text":"Loading... Please wait"},on:{"item-selected":_vm.clickedRow,"toggle-select-all":_vm.clickedAll},scopedSlots:_vm._u([{key:`header.data-table-select`,fn:function(){return [_vm._v("Selected")]},proxy:true},{key:`item.color`,fn:function({ item }){return _vm._l((_vm.group.find((f) => f.id == item.GroupId)?.allGroups),function(color){return _c('span',{key:color.id},[(color.Loop)?_c('span',[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"circle pointer",style:({
                  background: color.Color,
                  color: _vm.applyDark(color.Color) ? 'white' : 'black',
                }),on:{"click":function($event){return _vm.addLoop(item)}}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.Groups.find((f) => f.GroupId == color.id) ?.GroupIteration + 1))])]}}],null,true)},[_vm._v(_vm._s(color.Name))])],1):_c('span',[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"circle",style:({
                  background: color.Color,
                  color: _vm.applyDark(color.Color) ? 'white' : 'black',
                })},'span',attrs,false),on),[_vm._v("  ")])]}}],null,true)},[_vm._v(_vm._s(color.Name))])],1)])})}},{key:`item.Fields`,fn:function({ item }){return [(item.id)?_c('TestScenarioStepFields',{attrs:{"testScenarioLineStep":item,"disabled":!item.id}}):_vm._e()]}},{key:`item.TestRemark`,fn:function({ item }){return [_c('div',{staticClass:"testRemark",on:{"click":function($event){return _vm.clickEditTestRemark(item)}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.truncateHTML(item.TestRemark, 40))}}),_c('v-icon',{staticClass:"icon-top-right",attrs:{"small":"","disabled":!item.id},on:{"click":function($event){return _vm.clickEditTestRemark(item)}}},[_vm._v("mdi-pencil")])],1)]}},{key:`item.status.Testable`,fn:function({ item }){return [(item.Testable)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])]}},{key:"expanded-item",fn:function({ item }){return [(item?.Description)?_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('v-row',[_c('v-col',{staticClass:"mb-0 pb-0"},[_c('span',{staticClass:"ma-3 ql-editor",domProps:{"innerHTML":_vm._s(item?.Description)}})])],1)],1):_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e(),(_vm.showTestRemarkEditor)?_c('Modal',{attrs:{"title":"Edit test Remark","width":"1100px"},on:{"cancel":function($event){_vm.showTestRemarkEditor = false},"confirm":function($event){_vm.saveLineStep(_vm.currentItem);
      _vm.showTestRemarkEditor = false;}}},[_c('RichEditor',{attrs:{"disabled":!_vm.$can('update', _vm.currentItem, 'TestRemark')},model:{value:(_vm.currentItem.TestRemark),callback:function ($$v) {_vm.$set(_vm.currentItem, "TestRemark", $$v)},expression:"currentItem.TestRemark"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }