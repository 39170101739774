import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Profile from '../views/Profile.vue';
import Process from '../views/Process.vue';
import End2End from '../views/End2End.vue';
import Fields from '../views/Fields.vue';
import ProcessDetail from '../views/ProcessDetail.vue';
import ProcessStep from '../views/ProcessStep.vue';
import Comments from '../views/Comments.vue';
import Issues from '../views/Issues.vue';
import Requirement from '../views/Requirement.vue';
import Specification from '../views/Specification.vue';
import ProcessRequirement from '../views/ProcessRequirement.vue';
import FileUploader from '../views/FileUploader.vue';
import DocumentPicker from '../views/DocumentPicker.vue';
import Settings from '../views/Settings.vue';
import VendorResponse from '../views/VendorResponse.vue';
import Dashboard from '../views/Dashboard.vue';
import TestScenarios from '../views/TestScenarios.vue';
import TestScenarioHeader from '../views/TestScenarioHeader.vue';
import TestScenariosOverview from '../views/TestScenariosOverview.vue';
import TestRun from '../views/TestRun.vue';
import TestScenarioBuilder from '../views/TestScenarioBuilder.vue';
import Planner from '../views/Planner.vue';
import Drawing from '../views/Drawing.vue';
import CopyProcess from '../views/CopyProcess.vue';

import { config } from '@/config';

import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: `/${config.customer.home}`,
    meta: { requiresAuth: true, noVendor: true },
  },
  {
    path: '/login/:email?',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: { requiresAuth: true },
  },
  {
    path: '/process/:id?',
    name: 'Process',
    component: Process,
    meta: { requiresAuth: true, noVendor: true },
  },
  {
    path: '/end2end',
    name: 'End2End',
    component: End2End,
    meta: { requiresAuth: true, noVendor: true },
  },
  {
    path: '/fields',
    name: 'Fields',
    component: Fields,
    meta: { requiresAuth: true, noVendor: true },
  },
  {
    path: '/comments',
    name: 'Comments',
    component: Comments,
    meta: { requiresAuth: true, noVendor: true },
  },
  {
    path: '/issues',
    name: 'Issues',
    component: Issues,
    meta: { requiresAuth: true, noVendor: true },
  },

  {
    path: '/processdetail/:id&:number',
    name: 'processDetail',
    component: ProcessDetail,
    meta: { requiresAuth: true, noVendor: true },
  },
  {
    path: '/processstep/:id',
    name: 'processStep',
    component: ProcessStep,
    meta: { requiresAuth: true, noVendor: true },
  },
  {
    path: '/requirement',
    name: 'requirement',
    component: Requirement,
    meta: { requiresAuth: true, noVendor: true },
  },
  {
    path: '/specification/:id?/:type?',
    name: 'specification',
    component: Specification,
    meta: { requiresAuth: true, noVendor: true },
  },
  {
    path: '/testscenarios/:id?',
    name: 'testscenarios',
    component: TestScenarios,
    meta: { requiresAuth: true, noVendor: true },
  },
  {
    path: '/testscenarioheader',
    name: 'TestScenarioHeader',
    component: TestScenarioHeader,
    meta: { requiresAuth: true, noVendor: true },
  },
  {
    path: '/testscenariosoverview',
    name: 'TestScenariosOverview',
    component: TestScenariosOverview,
    meta: { requiresAuth: true, noVendor: true },
  },
  {
    path: '/testrun/:id?',
    name: 'testrun',
    component: TestRun,
    meta: { requiresAuth: true, noVendor: true },
  },
  {
    path: '/testscenariobuilder/:id',
    name: 'testscenariobuilder',
    component: TestScenarioBuilder,
    meta: { requiresAuth: true, noVendor: true },
  },
  {
    path: '/processRequirement/:id/:type',
    name: 'processRequirement',
    component: ProcessRequirement,
    meta: { requiresAuth: true, noVendor: true },
  },

  {
    path: '/fileuploader',
    name: 'fileUploader',
    component: FileUploader,
    meta: { requiresAuth: true, noVendor: true },
  },
  {
    path: '/documentpicker',
    name: 'DocumentPicker',
    component: DocumentPicker,
    meta: { requiresAuth: true, noVendor: true },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: { requiresAuth: true, noVendor: true },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true, noVendor: true },
  },
  {
    path: '/vendorresponse',
    name: 'VendorResponse',
    component: VendorResponse,
    meta: { requiresAuth: true },
  },
  {
    path: '/planner',
    name: 'planner',
    component: Planner,
    meta: { requiresAuth: true, noVendor: true },
  },
  {
    path: '/drawing',
    name: 'Drawing',
    component: Drawing,
    meta: { requiresAuth: true, noVendor: false },
  },
  {
    path: '/copyProcess',
    name: 'CopyProcess',
    component: CopyProcess,
    meta: { requiresAuth: true, noVendor: true },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    store
      .dispatch('auth/authenticate')
      .then(() => {
        if (to.meta.noVendor) {
          if (store.getters['auth/user'].role == 'vendor')
            next({ name: 'VendorResponse' });
          // else next(config.customer.home);
        }
        next();
      })
      .catch(() => {
        next({
          name: 'Login',
        });
      });
    //}
  } else next();
});

export default router;
